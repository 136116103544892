import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Download from "components/download"

export default () => (
  <div>
    <StaticQuery
      query={graphql`
        query DocumentsQuery {
          allMarkdownRemark(
            sort: {
              fields: [frontmatter___featured, frontmatter___date]
              order: [DESC, DESC]
            }
            filter: { frontmatter: { category: { regex: "/documents/" } } }
          ) {
            totalCount
            edges {
              node {
                id
                frontmatter {
                  date
                  title
                  file
                  featured
                }
              }
            }
          }
        }
      `}
      render={data => (
        <div>
          {data.allMarkdownRemark.edges.map(({ node }) => (
            <Download
              key={node.id}
              title={node.frontmatter.title}
              date={node.frontmatter.date}
              file={node.frontmatter.file}
              featured={node.frontmatter.featured}
            />
          ))}
        </div>
      )}
    />
  </div>
)
