import React from 'react'
import { Helmet } from 'react-helmet'
import Layout from 'layouts/layout'
import Header from 'components/header'
import Documents from './_documents'

export default () => (
  <Layout>
    <main>
      <Helmet>
        <title>
          Documents and papers | Helios Underwriting
        </title>
      </Helmet>
      <Header />
      <article className="o-content">
        <section>
          <div className="lg:u-flex">
            <h1 className="h1 lg:u-2/3 padding-md">
              Documents and papers
            </h1>
            <div className="lg:u-1/3 u-color-emperor padding-md">

              {/* If not using this section, only delete h2 and p */}

              {/* Do not delete wrapping div */}

            </div>
          </div>
        </section>

        <section>
          <Documents />
        </section>
      </article>

    </main>
  </Layout>
)